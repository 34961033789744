.steps {
    display: grid;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 6.5rem;
    margin-inline: auto;
    height: 100%;
    padding: 1rem 0.5rem;
    text-align: center;
    color: #727272;
    grid-column: span 2;

    border: 1px solid #ddd;
    background: #f1f1f1;
    border-radius: 10px;
    cursor: pointer;

    transition: all 0.2s ease-in-out;
}

.step_disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.active_step {
    color: #0a7aff;
    border-color: #0a7aff;
    background: #fff;
}

.step_count {
    width: 2rem;
    height: 2rem;
    position: relative;
}
.step_count > svg {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    transition: all 0.2s cubic-bezier(0.16, 0.06, 1, 1.7);
    transform-origin: top left;
}

.step_completed {
    opacity: 1;
    scale: 1;
}

.step_not_completed {
    opacity: 0;
    scale: 0;
}

.step_title {
    font-size: 0.75rem;
    font-weight: 400;
    color: inherit;
    margin: 0;
}

.step_separator {
    width: 100%;
    height: 1px;
    border-radius: 3rem;
    background: #ddd;
}
