.action_item {
    outline: none;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: transparent;
    padding: 0.5rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all ease-in-out 200ms;
}

.action_item:hover {
    background: #f0f2f5;
}
.action_item:disabled {
    cursor: not-allowed;
    opacity: 0.4;
}
.action_item:disabled:hover {
    background: transparent;
}

.action_item_icon {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.action_item_label {
    font-size: 1rem;
    font-weight: 600;
    color: #3e4a53;
}
