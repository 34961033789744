.horizontal_loader {
    width: calc(100% + 25px);
    height: 20px;
    overflow: hidden;
    transform: translateY(-3px);
    animation: move_right linear 1s infinite both;
    overflow: hidden;
}

@keyframes move_right {
    0% {
        transform: translateY(-3px) translateX(-25px);
    }
    100% {
        transform: translateY(-3px) translateX(0px);
    }
}
