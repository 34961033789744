.main_wrapper {
    background: #f5f6f8;
    background: linear-gradient(90deg, #f5f6f8 21px, transparent 1%) center, linear-gradient(#f5f6f8 21px, transparent 1%) center, #009ec1;
    background-size: 22px 22px;
    padding: 3rem 1.25rem;
    min-height: 100vh;
    overflow: auto;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.main_wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(2, 132, 161, 0.1);
    background: linear-gradient(103.59deg, rgba(19, 156, 122, 0.2) 3.61%, rgba(4, 149, 178, 0.2) 98.48%);
}

.logo {
    width: 100%;
    max-width: 12rem;
    margin-bottom: 3rem;

    position: relative;
    z-index: 2;
}

.container {
    background: #fff;
    border-radius: 0.75rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    max-width: 48rem;
    width: 100%;
    height: fit-content;
    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.title {
    background-color: #f5f6f8;
    border-bottom: 1px dashed #c0c0c0;
    border-radius: 0.75rem 0.75rem 0 0;
    width: 100%;
    padding: 1.25rem;
}

.title h1 {
    font-size: 1.1rem;
    font-weight: 700;
    color: #000;
    margin: 0;
    text-align: center;
}

.header {
    padding: 1.25rem;
}
.content_section {
    flex: 1;
    padding: 1.25rem;
    width: 90%;
}

.footer {
    background-color: #f5f6f8;
    border-top: 1px dashed #c0c0c0;
    border-radius: 0 0 0.75rem 0.75rem;
    width: 100%;
    padding: 0.75rem 1.25rem;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.8rem;
}

.action_btn {
    background-color: #009ec1;
    color: #fff;
    padding: 0.5rem 1.5rem;
    text-align: center;
    outline: none;
    border: none;
    border-radius: 0.4rem;
    font-size: 0.9rem;
    font-weight: 700;
    opacity: 1;

    transition: all 0.2s ease-in-out;
}

.action_btn:hover {
    background-color: #0088a3;
}
.action_btn:disabled,
.action_btn:disabled:hover {
    opacity: 0.5;
    background-color: #adadad;
}

.label {
    font-size: 0.75rem;
    font-weight: 400;
    color: #777;
    font-style: italic;
    margin-bottom: 0.2rem;
}

.logout_btn {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    background: transparent;
    border-radius: 0.3rem;
    color: #000;
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0.5rem;
    transition: all ease-in-out 150ms;

    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.logout_btn:hover {
    background: #f5f6f8;
}
