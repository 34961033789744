.wrapper {
    position: fixed;
    bottom: 0;
    right: 1.5rem;
    border-radius: 1rem 1rem 0 0;
    background-color: #f5f6f8;
    border: 1px solid #ddd;
    color: #030303;
    width: 100%;
    max-width: 22rem;
    transition: all ease-in-out 150ms;

    height: 355px;
}

.hidden {
    bottom: -300px;
}

.header {
    border-bottom: 1px solid #ddd;
    height: 55px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 0 1rem;
    cursor: pointer;
}

.header > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.8rem;
}
.header p {
    margin: 0;
    font-size: 0.95rem;
    font-weight: 500;
}

.close_button {
    background: #e7e8ea;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    transition: all ease-in-out 150ms;
}

.open {
    transform: rotate(-180deg);
}

.list_wrapper {
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.list_item {
    padding: 0.6rem 1rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
    cursor: pointer;
}

.list_item:hover {
    background-color: #e7e8ea;
}

.list_item p {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 500;
}

.not_found {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
